import {customElement, bindable} from "aurelia-framework";

@customElement('sio-textarea')
export class Textarea
{
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value = null;

    fieldChanged() {
        this.rows = this.field.rows ?? this.field.options?.rows ?? 5
    }
}
