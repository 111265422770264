import {customElement, bindable, inject} from "aurelia-framework";
import {Client} from '../api/client';
import {EventAggregator} from 'aurelia-event-aggregator';
import {FlashService} from "../flash/flash-service";

import "./time-tracking.less";

@customElement('sio-time-tracking-widget')
@inject(Client, EventAggregator, FlashService)
export class TimeTracking
{
    @bindable user;

    constructor(client, ea, flash)
    {
        this.client = client;
        this.ea = ea;
        this.flash = flash;
    }

    startTracking()
    {
        return this.client.post('human-resources/start-user-tracking').then(data => {

            this.ea.publish(
                'sio_form_post_submit',
                {config: {modelId: 'user/user'}}
            );

            return data;
        }, error => {
            let data = error.data;

            if(data && data.localizedMessage) {

                this.flash.error(data.localizedMessage);
            }

            return error;
        });
    }

    stopTracking()
    {
        return this.client.post('human-resources/stop-user-tracking').then(data => {

            this.ea.publish(
                'sio_form_post_submit',
                {config: {modelId: 'user/user'}}
            );

            return data;
        }, error => {
            let data = error.data;

            if(data && data.localizedMessage) {

                this.flash.error(data.localizedMessage);
            }

            return error;
        });
    }

}
