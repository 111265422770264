import {customElement, inject, bindable, observable} from "aurelia-framework";
import {ObserverLocator} from "aurelia-binding";
import {I18N} from 'aurelia-i18n';
import Client from "../api/client";
import {FlashService} from "../flash/flash-service";
import {UserClient} from "../api/user-client";

@inject(Client, FlashService, UserClient)
@customElement('sio-translatable')
export class Translatable
{
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value = null;

    locales = [
        'de'
    ];
    currentLocale = 'de';

    @observable currentValue = null;

    constructor(client, flash, userClient) {
        this.client = client;
        this.userClient = userClient;
        this.flash = flash;
        this.initialLoad = true;
    }

    setLocale(locale)
    {
        if (!this.value) {
            this.value = {};
        }

        this.value[this.currentLocale] = this.currentValue;

        this.currentLocale = locale;
        this.valueChanged();

        console.log('LOCALE', this.value);
    }

    async bind()
    {
        const user = this.userClient.getUser();

        this.locales = user.instance?.settings?.instanceGeneral?.contentLocales || ['de'];
        this.currentLocale = this.locales[0];

        this.currentValue = this.value?.[this.currentLocale] || null;
    }

    valueChanged()
    {
        this.currentValue = this.value[this.currentLocale] || null;
    }

    currentValueChanged(newValue)
    {
        if(!this.initialLoad){
            this.value = Object.assign({}, this.value ?? {}, {[this.currentLocale]: newValue});
        }else{
            if(newValue !== this.currentValue){
                this.value = Object.assign({}, this.value ?? {}, {[this.currentLocale]: newValue});
            }
            this.initialLoad = false;
        }
    }

    translateFrom(localeFrom)
    {
        const text = this.value[localeFrom] || null;

        if (text == '' || text == null) {
            return;
        }

        this.client.post('translate', {
            localeFrom: localeFrom,
            localeTo: this.currentLocale,
            text: this.value[localeFrom],
        }).then(response => {

            if (!response.data) {
                return;
            }

            this.currentValue = response.data;
        }).catch(error => {
            let data = error.data;

            if(data && data.message) {

                this.flash.error(data.message);
            };
        });
    }
}
